<template>
  <div id="requestRefundDialog">
    <el-dialog
      title="退款申请"
      :visible.sync="show"
      width="500px"
      @closed="reset"
    >
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="申请备注">
          <el-input
            v-model="form.applyRemark"
            placeholder="请输入申请备注"
          ></el-input>
        </el-form-item>
        <el-form-item label="退款金额" prop="requestAmount">
          <el-input
            v-model="form.requestAmount"
            placeholder="请输入申请备注，不填则退全部"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      orderId: "",
      form: {
        applyRemark: "",
        requestAmount: "",
      },
      rules: {
        requestAmount: [
          {
            pattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/,
            message: "退款金额必须大于0",
          },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$refs["form"].resetFields();
    },
    confirm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const res = await this.$http.post("/order/refund/request", {
              orderId: this.orderId,
              applyRemark: this.form.applyRemark,
              requestAmount: Number(
                parseFloat(this.form.requestAmount).toFixed(2)
              ),
            });
            console.log(res);
            if (res.code === 0) {
              this.$message.success("操作成功");
              this.$emit("onsuccess");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch {
            this.$message.error("操作失败");
            this.loading = false;
          }
        }
      });
    },
    handleShow() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="scss">
#requestRefundDialog {
}
</style>
